const articleObject = `
{
  id
  title
  slug
  featured_image
  created_at
  excerpt
  body
  category {
    id
    name
  }
  tags {
    tag {
      id
      name
    }
  }
  status {
    id
    name
  }
}
`
export default articleObject
