<template>
  <div
    v-if="article"
    class="article"
  >
    <div class="article-title-wrap">
      <b-button
        class="mr-3"
        variant="primary"
        @click="$router.go(-1)"
      >
        Go back
      </b-button>
      <h1 class="m-0">
        {{ article[0].title }}
      </h1>
    </div>
    <div class="article-featured-wrap">
      <img
        v-if="article[0].featuredImage != null"
        class="featured-image"
        :src="imgWithTransforms({ path: article[0].featuredImage, type: ['article', 'featured'] })"
      >
    </div>
    <div class="article-wrap">
      <div
        v-if="article != null"
        class="article-inner"
      >
        <div class="d-flex align-items-center mb-2">
          <div class="body-wrap">
            <div class="published">
              <h4
                v-for="articleAuthors in article[0].authors"
                :key="articleAuthors.user.id"
                class="m-0 mr-3"
              >
                Author {{ articleAuthors.user.name }}
              </h4>
              <h4
                class="m-0 mr-3"
              >
                Published {{ article[0].createdAt }}
              </h4>
            </div>
            <div
              v-if="article[0].body"
              class="article-body"
              v-html="article[0].body"
            />
          </div>
        </div>
        <b-button
          class="mt-4"
          variant="primary"
          @click="$router.go(-1)"
        >
          Go back
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import useArticles from '@/composables/articles/useArticles'
import useCloudinary from '@/composables/useCloudinary'
// import { onMounted } from '@vue/composition-api'
// import VimeoPlayer from '../components/vimeo/VimeoPlayer.vue'

export default {
  components: {
    BButton,
  },
  props: {
    id: {
      type: String,
      default: '0',
    },
  },
  setup() {
    const { imgWithTransforms } = useCloudinary()
    const { articleSingle: article } = useArticles()

    // onMounted(() => {
    //   const body = document.getElementsByClassName('article-body')
    //   const imgs = body[0].getElementsByTagName('img')
    //   if (imgs) {
    //     imgs.forEach(img => {
    //       const raw = img.src.split('/')
    //       const part = raw.slice(raw.length - 4)
    //       const newURL = imgWithTransforms({ path: `/${part[0]}/${part[1]}/${part[2]}/${part[3]}`, type: ['article', 'body'] })
    //       console.log('newURL', newURL)
    //       // eslint-disable-next-line no-param-reassign
    //       img.src = newURL
    //     })
    //   }
    // })

    return {
      article,
      imgWithTransforms,
    }
  },
}
</script>

<style lang="scss" >
[v-cloak] {
  display: none;
}

.dark-layout {
  .featured-wrap {
    border: 20px solid hsla(0, 0%, 100%, 0.08) !important;
    .featured-image {
      width: 100%;
    }
  }
}
</style>
