import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'
import snakeToCamel from '@/mixins/snakeToCamel'

const { http } = useApollo()

async function getArticleSingle({ articleId }) {
  const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
  const article = {}

  await http.value.query({
    variables: {
      id: Number(articleId),
    },
    query: gql`
        query getArticle($id: Int!) {
          ${frontSchema}_articles_by_pk(id: $id) {
            id
            title
            excerpt
            featured_image
            slug
            category {
              id
              name
            }
            tags {
              tag {
                id
                name
              }
            }
            status {
              id
              name
            }
            authors {
              user {
                name
                role
                avatar
              }
            }
            created_at
            updated_at
            body
          }
        }
      `,
  })
    .then(result => {
      const a = result.data[`${frontSchema}_articles_by_pk`]
      Object.keys(a).forEach(key => {
        const camelKey = snakeToCamel(key)
        article[camelKey] = a[key]
      })
      // so tags & authors play nice with vue-multiselect & cards
      const tags = []
      article.tags.forEach(t => {
        tags.push(t.tag)
      })
      article.tags = tags
      const authors = []
      article.authors.forEach(at => {
        authors.push(at.user)
      })
      article.authors = authors
    })
    .catch(err => console.error('getArticleSingle', err))

  return article
}

export default getArticleSingle
