import {
  ref,
  computed,
  // watch,
} from '@vue/composition-api'
import useApollo from '@/plugins/apolloClient'
import { gql } from '@apollo/client/core'
import snakeToCamel from '@/mixins/snakeToCamel'
import getArticleSingle from './getArticleSingle'
import articleObject from './articleObject'
import useCategories from '../useCategories'

const articles = ref({
  pageSize: 30,
  activeId: null,
  all: [],
})

export default function useArticles() {
  const {
    // categoriesList,
    categoryActive,
  } = useCategories()
  const frontSchema = process.env.VUE_APP_HASURA_SCHEMA_FRONT
  const { http } = useApollo()

  function processArticles({ rawArts }) {
    const art = []
    rawArts.forEach(a => {
      let artObj = {}
      Object.keys(a).forEach(key => {
        const camelKey = snakeToCamel(key)
        artObj[camelKey] = a[key]
      })
      art.push(artObj)
      artObj = null
    })
    return art
  }

  async function getArticlesAll() {
    const offset = articles.value.all.length
    await http.value.query({
      query: gql`
        query getArticlesAll {
          ${frontSchema}_articles(
          order_by: {created_at: desc},
          limit: ${articles.value.pageSize},
          offset: ${offset},
          where: {status: {name: {_eq: "Published"}}})
          ${articleObject} 
        }
      `,
    })
      .then(result => {
        const rawArts = result.data[`${frontSchema}_articles`]
        const processedArts = processArticles({ rawArts })
        const filtered = processedArts.filter(art => !articles.value.all.some(a => a.id === art.id))
        articles.value.all.push(...filtered)
      })
      .catch(err => console.error('getAllArticles', err))
  }

  // async function getArticlesByCategory(cat) {
  //   await http.value.query({
  //     variables: {
  //       id: cat.id,
  //     },
  //     query: gql`
  //       query getArticlesByCategory($id: Int!) {
  //         ${frontSchema}_articles(
  //         order_by: {created_at: desc},
  //         where: {status: {name: {_eq: "Published"}},
  //          _and: {category_id: {_eq: $id }}})
  //         ${articleObject}
  //       }
  //     `,
  //   })
  //     .then(result => {
  //       const catName = cat.name.toLowerCase()
  //       const rawArts = result.data[`${frontSchema}_articles`]
  //       const processedArts = processArticles({ rawArts })
  //       const filtered = processedArts.filter(art => !articles.value[catName].some(a => a.id === art.id))
  //       console.log('checkingArticlesByCat', filtered, catName)
  //       articles.value[catName].push(...filtered)
  //     })
  //     .catch(err => console.log('error getting articles by category', err))
  // }

  // async function getArticleDrafts() {
  //   const offset = articles.value.all.length
  //   const limit = articles.value.pageSize
  //   await http.value.query({
  //     query: gql`
  //       query getArticleDrafts {
  //         ${frontSchema}_articles(
  //         limit: ${limit},
  //         offset: ${offset},
  //         where: {status: {name: {_eq: "Draft"}}})
  //         ${articleObject}
  //       }
  //     `,
  //   })
  //     .then(result => {
  //       const rawArts = result.data[`${frontSchema}_articles`]
  //       const processedArts = processArticles({ rawArts })
  //       const filtered = processedArts.filter(art => !articles.value.drafts.some(draft => draft.id === art.id))
  //       articles.value.drafts.push(...filtered)
  //     })
  //     .catch(err => console.log('error getting articles by category', err))
  // }

  async function getArticle({ articleId }) {
    articles.value.single = await getArticleSingle({ articleId })
  }

  async function setArticleActive({ articleId }) {
    articles.value.activeId = articleId
  }

  // watch(() => categoriesList.value, gotList => {
  //   console.log('getArticleByCat', gotList)
  //   gotList.forEach(cat => {
  //     getArticlesByCategory(cat)
  //   })
  // })

  return {
    articles: computed(() => {
      if (categoryActive.value.name !== 'new') {
        return articles.value.all.filter(a => a.category.id === categoryActive.value.id)
      } return articles.value.all
    }),
    articleDrafts: computed(() => articles.value.drafts),
    articleSingle: computed(() => articles.value.all.filter(a => a.id === articles.value.activeId)),
    getArticlesAll,
    getArticle,
    setArticleActive,
  }
}
